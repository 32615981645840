<template>
  <div class="dynamic-content container-fluid px-0">
    <b-jumbotron
      class="text-center text-white headBanner headerBG side_distance header3"
    >
      <b-container class="row align-items-end mx-auto column-xxl">
        <b-col
          cols="12"
          xl="7"
          class="verticalSpacing mx-auto ml-lg-0 text-lg-left p-0"
        >
          <div class="header-banner-circle-images">
            <div class="img-lg">
              <img src="@/assets/img/header-banner-lg-circle.png" alt="" />
            </div>
            <div class="img-sm">
              <img src="@/assets/img/header-banner-sm-circle.png" alt="" />
            </div>
          </div>
          <div class="text-white my-5 headerBannerTitle">
            <h1 v-html="$t('Buy.Header.FindCarsInternationally')"></h1>
            <div class="header-banner-arrow">
              <img src="@/assets/img/header-banner-arrow.svg" alt="" />
            </div>
          </div>

          <div class="headerText">
            {{ $t("Buy.Header.BuyOrSaleInYourCountry") }}
          </div>
          <div id="header-getstarted-container">
            <router-link :to="{
            name: 'HelpInformation',
            params: {
              subsection: $t('Footer.Footer.Buyers').toLowerCase(),
              type: $t('HelpCenter.Buyer.BuyWithUs')
                .replaceAll(' ', '-')
                .toLowerCase()
            }}">
              <b-button variant="primary" class="btn btn-header-getstarted px-10">
                  {{ $t("Header.MainHeader.LearnMore") }}
              </b-button>
            </router-link>
          </div>
        </b-col>
        <TheSearchCard2 :text="text" class="search-card" />
      </b-container>
    </b-jumbotron>
  </div>
</template>

<script>
import TheSearchCard2 from "@/components/global/TheSearchCard2.vue";

export default {
  name: "Home",
  components: {
    TheSearchCard2,
  },
  props: {
    text: {
      type: Array,
    },
  },
};
</script>
<style lang="scss">
.headerBannerTitle {
  position: relative;
  h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 68px;
    text-transform: capitalize;
    color: #1c1c1c;
    span {
      color: $light-blue;
    }
    @media (max-width: 1199px) {
      font-size: 40px;
      line-height: 56px;
      text-align: center;
    }

    @media (max-width: 575px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
}
</style>
<style scoped lang="scss">
.search-card {
  margin-bottom: 85px;
}
.headerBG {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  position: relative;
  z-index: 2;
  min-height: 660px;
  @media (max-width: 575px) {
    min-height: 735px;
  }
}

.headerBG:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  /* background: rgba(0, 81, 165, 0.35); */
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -1;
}
.side_distance {
  padding-top: 250px;
  padding-bottom: 0;
  border-radius: 0;
  margin-bottom: 0;
  @media (max-width: 1200px) {
    padding-top: 210px !important;
    padding-bottom: 60px;
  }
  @media (min-width: 1200px) {
    &.row{
      flex-wrap: nowrap;
    }
  }
  @media (max-width: 575px) {
    padding-bottom: 0 !important;
  }
}
.verticalSpacing {
  margin-bottom: 85px;
  max-width: 728px;
}
.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  max-width: 525px;
  color: #232323;
}

@media (max-width: 1199px) {
  .headerBG {
    background-size: cover !important;
  }
  .verticalSpacing {
    max-width: 100%;
    margin-bottom: 24px;
  }
  .verticalSpacing h1 {
    font-size: 40px;
    line-height: 56px;
    text-align: center;
  }
  .headerText {
    font-size: 18px;
    line-height: 27px;
    max-width: 357px;
    margin: auto;
    text-align: center;
  }
  .column-xxl {
    flex-direction: column;
  }
  .search-card {
    margin-bottom: 0;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .headerBG {
    background-size: auto 100%;
  }
  .side_distance h1,
  .headerText {
    text-align: center;
    margin: auto;
  }
}

@media (max-width: 767px) {
  .side_distance h1 {
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .headerBG {
    background-size: 470px !important;
    background-position: right bottom !important;
  }
  .side_distance {
    padding: 0;
    padding-top: 0;
  }
  .side_distance h1 {
    font-size: 28px;
    line-height: 40px;
    margin-top: 30px;
  }
  .verticalSpacing {
    margin-bottom: 24px;
  }
  .headerText {
    font-size: 16px;
    line-height: 24px;
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
  }
}

#header-getstarted-container {
  max-width: fit-content;
}
.btn-header-getstarted {
  width: 100%;
  height: 56px;
  border-radius: 40px;
  font-weight: 600;
  font-size: 20px;
  margin-top: 32px;
  @media (max-width: 1200px) {
    display: none !important;
  }
}
.header-banner-arrow {
  position: absolute;
  right: 10px;
  top: -130px;
  @media (max-width: 1200px) {
    display: none;
  }
}
.header-banner-circle-images {
  display: flex;
  column-gap: 10px;
  justify-content: flex-end;
  margin-right: 130px;
  margin-top: -25px;
  position: relative;
  top: -10px;
  z-index: -1;
  @media (max-width: 1200px) {
    position: absolute;
    left: -2%;
    flex-direction: column;
    top: 55%;
  }
  @media (max-width: 767px) {
    left: -5%;
    top: 90%;
    width: 120px;
    height: 120px;
  }
  @media (max-width: 575px) {
    position: static;
    margin-top: 0;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
  .img-sm {
    position: relative;
    top: -10px;
    background: $yellow;
    border-radius: 100%;
    padding-top: 4px;
    height: 132px;
    @media (max-width: 1200px) {
      width: 106px;
      height: 106px;
      padding-top: 3px;
      top: 15px;
      left: 60px;
    }
    @media (max-width: 767px) {
      width: 106px;
      height: 106px;
    }
    @media (max-width: 575px) {
      top: -12px;
      left: 0;
    }
  }
  .img-lg {
    background: $yellow;
    border-radius: 100%;
    padding-bottom: 6px;
    @media (max-width: 1200px) {
      width: 142px;
      height: 142px;
      padding-bottom: 4px;
    }
    @media (max-width: 575px) {
      top: -12px;
      left: 0;
    }
  }
}
</style>
<style lang="scss">
.side_distance {
  @media (min-width: 1200px) {
    .row{
      flex-wrap: nowrap !important;
    }
  }
}
</style>
