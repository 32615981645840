<template>
  <div class="mx-auto">
    <!-- BEGIN:: Search Card -->
    <a id="searchAnchor"></a>
    <div class="card form-wrapper">
      <form @submit.prevent="searchSubmit($t('Buy.Link.Search'))">
        <div class="card-header">
          <h6 class="h4 mb-0 form-header">
            {{ $t("Buy.Header.FindNextCar") }}
            <!-- Find your next car! -->
          </h6>
        </div>
        <div class="card-body homepage-form-body">
          <div :class="countryCode ? 'has-flag' : ''">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :label="$t(`Buy.Header.DeliveryCountry`)"
              :items="activeBuyerCountries"
              background-color="#fff"
              v-model="searchCountry"
              item-value="Code"
              item-text="Name"
              outlined
              light
              dense
              return-object
              ref="countrySelector"
              class="select-country"
              @change="countryItem($event)"
            >
              <template v-slot:prepend>
                <country-flag :country="countryCode" class="flag" v-if="countryCode" />
                <img src="@/assets/img/globe.png" alt="" v-else />
              </template>

              <template #item="{ item }">
                <country-flag :country="item.Code ? item.Code : ''" class="flag" />
                {{ item.Name }}
              </template>
              <template v-slot:append-item>
                <v-divider class="my-1" />
                <v-list-item
                  @click="searchCountry = 'noCountry'"
                  style="cursor: pointer"
                  class="primary--text"
                >
                  {{ $t("topbar.button.noCountry") }}
                </v-list-item>
              </template>
            </v-select>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :label="
                currency
                  ? $t(`Buy.Header.ShowPricesInCurrency`)
                  : $t(`Buy.Header.SelectCurrency`)
              "
              :items="currenciesForBuyer"
              background-color="#fff"
              item-value="Code"
              :value="currency ? currency.trim() : undefined"
              v-model="currency"
              outlined
              light
              dense
            >
              <template #selection="{ item }">
                {{ ['XAF','XOF'].includes(item.Code) ? "FCFA" : item.Code }}
              </template>
              <template #item="{ item }">
                <span v-if="['XAF','XOF'].includes(item.Code)"> {{item.Sign}} - {{ item.CurrencyName }} </span>
                <span v-else> {{ item.Code }} - {{ item.CurrencyName }} </span>
              </template>
            </v-select>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :disabled="!searchCountryInput || filterLoading"
              :label="$t(`Header.chooseMake`)"
              background-color="#fff"
              item-value="TagValueId"
              :loading="filterLoading"
              :items="getMakes"
              item-text="Value"
              v-model="make"
              multiple
              outlined
              light
              dense
              clearable
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="selectAllMakes">
                  <v-list-item-action>
                    <v-icon :color="make.length > 0 ? 'indigo darken-4' : ''">
                      {{ makeSelectAllIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{$t(`Header.selectAll`)}} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"/>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" small>
                  <span>{{ item.Value }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ make.length - 2 }} {{$t(`Header.others`)}})
                </span>
              </template>
            </v-select>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :disabled="!searchCountryInput || make.length < 1 || filterLoading"
              :loading="filterLoading"
              :label="$t(`Header.chooseModel`)"
              background-color="#fff"
              :items="getModels"
              v-model="model"
              multiple
              outlined
              light
              dense
              clearable
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="selectAllModels">
                  <v-list-item-action>
                    <v-icon :color="model.length > 0 ? 'indigo darken-4' : ''">
                      {{ modelSelectAllIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> {{$t(`Header.selectAll`)}} </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 2" small>
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 2" class="grey--text text-caption">
                  (+{{ model.length - 2 }} {{$t(`Header.others`)}})
                </span>
              </template>
            </v-select>
          </div>
          <div class="checkbox-container d-inline-block mx-auto position-relative">
            <div v-if="firstVisit === true" class="position-absolute choose-notif rounded p-3" :class="isFr ? 'choose-notif-fr' : ''">
              <div class="d-flex gap-2">
                <div class="bg-success rounded-pill py-1 px-2 fs-13" style="height:fit-content;">{{ $t('Buy.New') }}</div>
                <div class="text-left">
                  <p>
                    {{ $t('Buy.Search.PopupMessage') }}
                  </p>
                  <button type="button" class="bg-primary py-1 px-3 fs-14 w-100 rounded-pill" @click="(event) => {event.preventDefault(), firstVisit = false}">
                    {{ $t('Validations.email.gotIt') }}
                  </button>
                </div>
              </div>
            </div>
            <v-checkbox
            v-model="includeFees"
            :label="$t('Overview.fees.included')">
          </v-checkbox>
        </div>
          <div>
            <v-btn
              color="#F3B106"
              class="black--text"
              block
              type="submit"
              large
              :loading="searchResultLoading || filterLoading"
              :disabled="searchResultLoading || filterLoading"
            >
              <!-- <v-icon dark>mdi-car-search-outline</v-icon> -->
              {{$t(`Header.letsGO`)}}
              <!-- <span v-if="searchResult" class="pl-2 font-weight-bold"
                >for {{ searchResult }} results</span
              > -->
            </v-btn>
          </div>
        </div>
      </form>
    </div>
    <!-- END:: Search Card -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {searchLastPosition} from "@/views/pages/Search.vue";
import includeFees from "@/components/mixins/includeFees.js"

export default {
  watch: {
    searchCountryInput: async function () {
      if (!this.filters) await this.getFilter();
     // await this.getFilterResult();
    },
    make: function () {
      this.model = [];
    },
  },
  mixins: [includeFees],
  data() {
    return {
      firstVisit: null,
      searchCountryInput: undefined,
      make: [],
      model: [],
      searchResult: false,
      searchResultLoading: false,
      filterLoading: false,
      countryCode: "",
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "filters",
      "getUserFilterList",
      "currencies",
      "currenciesForBuyer",
      "activeBuyerCountries",
      "getUserFilterValToLabel",
    ]),
    isFr() {
      return localStorage.getItem('locale') === 'fr'
    },
    getMakes() {
      try {
        return this.getUserFilterList("Make");
      }catch (error){
        console.log(error);
        return []
      }

    },
    getModels() {
      try {
        const models = this.getUserFilterList("Model", this.make, "Make");
        if (models) {
          return Object.keys(models).reduce((arr, item) => {
            return [
              ...arr,
              ...[
                {
                  text: item,
                  value: item,
                  divider: true,
                  header: item,
                },
                ...models[item].map((item) => ({
                  text: item.Value,
                  value: item.TagValueId,
                  disabled: false,
                  divider: false,
                })),
              ],
            ];
          }, []);
        }
        return [];
      }catch (error){
        console.log(error);
        return []
      }
    },
    makeSelectAllIcon() {
      if (this.selectAllMake) return "mdi-close-box";
      if (this.selectSomeMake) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    modelSelectAllIcon() {
      if (this.selectAllModel) return "mdi-close-box";
      if (this.selectSomeModel) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllMake() {
      return this.make.length === this.getMakes.length;
    },
    selectSomeMake() {
      return this.make.length > 0 && !this.selectAllMake;
    },
    selectAllModel() {
      return this.model.length === this.getModels.length - this.make.length;
    },
    selectSomeModel() {
      return this.model.length > 0 && !this.selectAllModel;
    },
    isBuyerCurrency() {
      let currencyArr = [];
      this.currenciesForBuyer.forEach((e) => {
        currencyArr.push(e.Code);
      });
      return currencyArr.includes(this.currency);
    },
    searchCountry: {
      get() {
        try {
          if (this.$store.state.search.destinationCountry) {
            return this.$store.state.search.destinationCountry
          } else if (this.$store.state.user.country && this.$store.state.user.country.CanBuy) {
            return this.$store.getters.country;
          } else {
            return undefined;
          }
        }catch (error){
          console.log(error);
        }
      },
      set(country) {
        try {
          if (country === "noCountry") {
            this.$bvModal.show("deliveryCountryNotFound");
          } else {
            this.searchCountryInput = country;
            this.$store.dispatch("destinationCountry", country);
          }
        }catch (error){
          console.log(error);
        }
      },
    },
    currency: {
      get() {
        return this.$store.state.user.currency;
      },
      set(currency) {
        this.$store.dispatch("setCurrency", currency);
      },
    },

  },
  methods: {
    selectAllMakes() {
      this.$nextTick(() => {
        if (this.selectAllMake) {
          this.make = [];
        } else {
          this.make = this.getMakes.map((item) => item.TagValueId);
        }
      });
    },
    selectAllModels() {
      this.$nextTick(() => {
        if (this.selectAllModel) {
          this.model = [];
        } else {
          this.model = this.getModels
            .filter((item) => !item.divider)
            .map((item) => item.value);
        }
      });
    },
    searchSubmit(link) {
      try {
        if (this.searchCountryInput) {
          localStorage.setItem("destCountry", JSON.stringify(this.searchCountryInput));
          this.$store.dispatch('setPageAttr',{
            LastUrl: null
          });
          localStorage.removeItem(searchLastPosition)
            this.$router.push({
              path: `/${link}`,
              params: {country: this.searchCountryInput.Code, currency: this.currency},
              query: {
                Make: this.getUserFilterValToLabel("Make", this.make),
                Model: this.getUserFilterValToLabel("Model", this.model),
              },
            });
        } else {
          this.$store.dispatch("showToast", {
            content: this.$t("Validations.Search.NoCountryAndCurrency"),
            type: "error",
          });
          this.$refs.countrySelector.focus();
          this.$refs.countrySelector.activateMenu();
        }
      }catch (error){
        console.log(error);
      }
    },
    setCurrencyInput(currency) {
      this.$store.commit("currency", currency);
      localStorage.setItem("currency", currency);
    },
    //@deprecated
    async getFilterResult() {
      //disabled because we don't need it
      console.log('This function is deprecated.')
      try {
        this.searchResultLoading = true;
        const Tags = [];
        if (this.make.length > 0) {
          Tags.push({
            Values: [...this.make.map((item) => item.toString())],
            TagId: 2,
          });
        }
        if (this.model.length > 0) {
          Tags.push({
            Values: [...this.model.map((item) => item.toString())],
            TagId: 3,
          });
        }
        this.searchResult = await this.$store.dispatch("getListingsLite", {
          DeliveryCountryCode: this.searchCountryInput.Code,
          Tags: Tags ? Tags : null,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.searchResultLoading = false;
      }
    },
    async getFilter() {
      try {
        this.filterLoading = true;
        await this.$store.dispatch("getListingsFiltered", {
          country: this.searchCountryInput.Code,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.filterLoading = false;
      }
    },
    countryItem(e) {
      this.countryCode = e.Code;
    },
  },
  mounted() {
    if (this.user) this.firstVisit = false
    else this.firstVisit = true
  },
  beforeMount() {
    try {
      const destinationCountry = this.$store.state.search.destinationCountry;
      if (destinationCountry) {
        this.searchCountryInput = destinationCountry;
      }
      this.searchCountryInput = this.searchCountry;
    }catch (error){
      console.log(error);
    }
    //this.$store.dispatch("destinationCountry", null);
  },
};
</script>
<style scoped lang="scss">
.form-wrapper {
  width: 460px;
  /* height: 500px; */
  background: white;
  border-radius: 10px;
  border: none;
  padding: 24px;
}

@media (max-width: 1200px) {
  .form-wrapper {
    width: 390px;
    height: 460px;
    padding: 16px;
    height: auto;
    margin: auto;
  }
}

@media (max-width: 575px) {
  .form-wrapper {
    width: 100%;
    margin-bottom: 40px;
  }
}

.form-header {
  font-weight: 700;
  font-size: 24px;
  color: $light-blue;
  margin-bottom: 24px !important;
  text-transform: capitalize;
}
.card-header {
  padding: 0;
  background: none;
  border: none;
}
.card-body {
  background: white;
  padding: 0;
}
.flag {
  border-radius: 100% !important;
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
}
</style>

<style scoped lang="scss">
.f-cm {
  background-position: -6px -1968px !important;
}
.f-sn {
  background-position: -6px -8693px !important;
}
.f-ci {
  background-position: -6px -1845px !important;
}

.choose-notif {
  background-color:#1c1c1c; 
  z-index:1;
  bottom: -9em;
  @media screen and(min-width:1200px) {
    right:22em;
    bottom:-2.6em; 
    width: 300px;
  }
&:after {
  border-bottom: 10px solid #1c1c1c;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -.55em;
  left:47%;
  @media screen and(min-width:1200px) {
    border-right:unset;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #1c1c1c;
    top: 4em;
    left: 18.7em;
  }
  position: absolute;
  content:'';
  width: 0;
  height: 0;
}
}

.choose-notif-fr {
  bottom: -10em;
  @media screen and(min-width:1200px) {
    right:23.5em;
    bottom:-4.5em; 
  }
&:after {
  @media screen and(min-width:1200px) {
    top: 5em;
  }
}
}

</style>
