var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dynamic-content container-fluid px-0"},[_c('b-jumbotron',{staticClass:"text-center text-white headBanner headerBG side_distance header3"},[_c('b-container',{staticClass:"row align-items-end mx-auto column-xxl"},[_c('b-col',{staticClass:"verticalSpacing mx-auto ml-lg-0 text-lg-left p-0",attrs:{"cols":"12","xl":"7"}},[_c('div',{staticClass:"header-banner-circle-images"},[_c('div',{staticClass:"img-lg"},[_c('img',{attrs:{"src":require("@/assets/img/header-banner-lg-circle.png"),"alt":""}})]),_c('div',{staticClass:"img-sm"},[_c('img',{attrs:{"src":require("@/assets/img/header-banner-sm-circle.png"),"alt":""}})])]),_c('div',{staticClass:"text-white my-5 headerBannerTitle"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('Buy.Header.FindCarsInternationally'))}}),_c('div',{staticClass:"header-banner-arrow"},[_c('img',{attrs:{"src":require("@/assets/img/header-banner-arrow.svg"),"alt":""}})])]),_c('div',{staticClass:"headerText"},[_vm._v(" "+_vm._s(_vm.$t("Buy.Header.BuyOrSaleInYourCountry"))+" ")]),_c('div',{attrs:{"id":"header-getstarted-container"}},[_c('router-link',{attrs:{"to":{
          name: 'HelpInformation',
          params: {
            subsection: _vm.$t('Footer.Footer.Buyers').toLowerCase(),
            type: _vm.$t('HelpCenter.Buyer.BuyWithUs')
              .replaceAll(' ', '-')
              .toLowerCase()
          }}}},[_c('b-button',{staticClass:"btn btn-header-getstarted px-10",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Header.MainHeader.LearnMore"))+" ")])],1)],1)]),_c('TheSearchCard2',{staticClass:"search-card",attrs:{"text":_vm.text}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }